@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	padding-bottom: 20px;
	position: relative;

	&.error {

		.radio:not([disabled]:not(.interactionDisabled)) {

			& + .itemLabel {

				color: var(--input-error-color);


				&:before {
					border-color: var(--input-error-color);
				}

			}

		}

		.message {
			color: var(--input-error-message-color);
		}

	}

}

.item {

	cursor: pointer;
	position: relative;
	margin-bottom: 10px;

	&:not(:first-child) {

		.itemLabel {

			&.styleSwitch {

				&:before {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}

			}

		}

	}

	@for $i from 2 through 10 {

		&:nth-child(#{$i}) {

			.itemLabel {

				&.styleSwitch {
					transform: translate(-#{$i - 1}px, 0);
				}

			}

		}

	}

	&:not(:last-child) {

		margin-right: 10px;

		.itemLabel {

			&.styleSwitch {

				margin-right: -10px;

				&:before {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

			}

		}

	}

}

.label {

	display: block;
	margin-bottom: 10px;
	font: var(--radio-button-label-font);
	color: var(--input-label-color);

}

.inner {

	display: flex;
	flex-direction: row;

	&:not(.noSpace) {
		flex-wrap: wrap;
	}

	&.noSpace {
		.item {
			flex-grow: 1;
			&:not(:last-child) {
				margin-right: 0;
			}
		}
	}

	&.column {
		flex-direction: column;
	}

}

.itemLabel {

	font: var(--radio-button-label-font);
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;

	sup {
		font-size: 10px;
		display: inline-block;
		transform: translate(2px, -5px);
	}

	&.s {

		height: var(--radio-button-height-s-mobile);
		padding: var(--radio-button-padding-s-mobile);

		@include bp-min(sm) {
			height: var(--radio-button-height-s-tablet);
			padding: var(--radio-button-padding-s-tablet);
		}

		@include bp-min(lg) {
			height: var(--radio-button-height-s-desktop);
			padding: var(--radio-button-padding-s-desktop);
		}

	}

	&.m {

		height: var(--radio-button-height-m-mobile);
		padding: var(--radio-button-padding-m-mobile);

		@include bp-min(sm) {
			height: var(--radio-button-height-m-tablet);
			padding: var(--radio-button-padding-m-tablet);
		}

		@include bp-min(lg) {
			height: var(--radio-button-height-m-desktop);
			padding: var(--radio-button-padding-m-desktop);
		}

	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: calc(100% - var(--radio-button-border-width) - var(--radio-button-border-width));
		height: calc(100% - var(--radio-button-border-width) - var(--radio-button-border-width));
		pointer-events: none;
		border-width: var(--radio-button-border-width);
		border-style: solid;
		border-radius: var(--radio-button-border-radius);
	}

	&.styleDefault {

		color: var(--radio-button-default-label-color);
		padding: 0;
		padding-left: calc(var(--radio-button-size-mobile) + var(--radio-button-default-label-space));
		width: 100%;
		height: auto;
		min-height: var(--radio-button-size-mobile);
		margin-bottom: 5px;

		@include bp-min(sm) {
			padding-left: calc(var(--radio-button-size-tablet) + var(--radio-button-default-label-space));
			height: auto;
			min-height: var(--radio-button-size-tablet);
		}

		@include bp-min(lg) {
			padding-left: calc(var(--radio-button-size-desktop) + var(--radio-button-default-label-space));
			height: auto;
			min-height: var(--radio-button-size-desktop);
		}

		.check {
			position: absolute;
			top: 1px;
			left: 0;
		}

		&:before {
			display: none;
		}

		&:after {
			content: '';
			display: none;
		}

		.textWrapper {
			display: flex;
			flex-direction: row;
		}

		.text {
			flex-grow: 1;
		}

		.textExtension {
			flex-grow: 0;
			flex-shrink: 0;
			font-style: normal;
			padding-left: 10px;
		}

	}

	&.styleButton {

		color: var(--radio-button-button-label-color);

		&:before {
			border-color: var(--radio-button-button-border-color);
			background-color: var(--radio-button-button-background-color);
		}

	}

	&.styleSwitch {

		color: var(--radio-button-switch-label-color);

		&:before {
			border-color: var(--radio-button-switch-border-color);
			background-color: var(--radio-button-switch-background-color);
		}

	}

	&.styleTab {

		font-size: 12px;
		padding-top: 5px;
		padding-bottom: 5px;
		white-space: nowrap;
		text-align: center;

		&:before {
			border: none;
			border-bottom: 2px solid transparent;
			border-radius: 0;
			width: 100% !important;
		}

	}

}

.radio {

	opacity: 0.0000001;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	&:checked {

		& + .itemLabel {

			&:before {
				width: calc(100% - var(--radio-button-border-width-active) - var(--radio-button-border-width-active));
				height: calc(100% - var(--radio-button-border-width-active) - var(--radio-button-border-width-active));
				border-width: var(--radio-button-border-width-active);
			}

			&.styleDefault {

				&:after {
					transform: scale(1);
				}

			}

			&.styleButton {

				color: var(--radio-button-button-label-color-active) !important;

				&:before {
					border-color: var(--radio-button-button-border-color-active) !important;
					background-color: var(--radio-button-button-background-color-active) !important;
				}

			}

			&.styleSwitch {

				color: var(--radio-button-switch-label-color-active) !important;

				&:before {
					border-color: var(--radio-button-switch-border-color-active) !important;
					background-color: var(--radio-button-switch-background-color-active) !important;
				}

			}

			&.styleTab {

				&:before {
					border-bottom-color: var(--radio-button-switch-border-color-active) !important;
				}

			}

		}

	}

	&:disabled {

		& + .itemLabel {
			cursor: not-allowed;
		}

	}

	&:disabled:not(.interactionDisabled) {

		& + .itemLabel {

			&.styleButton {

				color: var(--radio-button-button-label-color-disabled);

				&:before {
					border-color: var(--radio-button-button-border-color-disabled);
					background-color: var(--radio-button-button-background-color-disabled);
				}

			}

			&.styleSwitch {

				color: var(--radio-button-switch-label-color-disabled);

				&:before {
					border-color: var(--radio-button-switch-border-color-disabled);
					background-color: var(--radio-button-switch-background-color-disabled);
				}

			}

			&.styleTab {

				color: var(--radio-button-switch-label-color-disabled);

			}

		}

	}

}

.messageDefault {
	bottom: 6px;
}
