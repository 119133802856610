@import 'assets/styles/mixins/_breakpoints.scss';

.wrapper {

	display: flex;
	flex-direction: column;
	justify-content: center;

	@include bp-min(sm) {
		flex-direction: row;
	}

}
