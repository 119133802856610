$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;
$primaryGreyBlue: #63818E;

$primarySky: #DEEDF2;
$primarySkyLight: #f8fdfe;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;

$extraSky: #c3dee7;
$extraSkyLight: #F2F8FA;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;
$secondaryTurquoiseLight: #38CBD6;
$secondaryTurquoiseExtraLight: #D2F0F2;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #9E3667;
$secondaryPurpleLight: #B9638B;
$secondaryPurpleLighter: #fbf5f8;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$secondaryYellow: #FFCC33;
$secondaryYellowLight: #fffaeb;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenLight: #f7fbf7;
$secondaryGreenDark: #3A8340;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

// Link
$linkColor: $secondaryPurple;
$linkColorActive: $secondaryPurpleDark;

// Check
$checkBackground: #f2f8fa;

// Progress Bar
$progressBarBackground: #f2f8fa;
