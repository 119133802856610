@import 'src/assets/styles/mixins/_breakpoints.scss';

.input {

	appearance: none;
	cursor: pointer;
	line-height: 1.3;

	&[disabled] {

		& ~ .pseudoSelect {
			color: var(--input-disabled-color);
			border-color: var(--input-disabled-border-color);
			background-color: var(--input-disabled-background-color);
			pointer-events: none;
			cursor: not-allowed;
		}

		& ~ .label {

			color: var(--input-label-color-disabled);
			top: var(--input-label-position-top-mobile-active);

			@include bp-min(sm) {
				top: var(--input-label-position-top-tablet-active);
			}

			@include bp-min(lg) {
				top: var(--input-label-position-top-desktop-active);
			}

		}

		& ~ .arrow {
			opacity: 0.5;
		}

		&.s {

			& ~ .label {

				top: var(--input-label-position-top-s-mobile-active);

				@include bp-min(sm) {
					top: var(--input-label-position-top-s-tablet-active);
				}

				@include bp-min(lg) {
					top: var(--input-label-position-top-s-desktop-active);
				}

			}

		}

	}

	&:not(.active):not([disabled]):focus,
	&:not(.active):not([disabled]):active {

		& ~ .label {

			font-size: var(--input-label-font-size-mobile);
			top: var(--input-label-position-top-mobile);

			@include bp-min(sm) {
				font-size: var(--input-label-font-size-tablet);
				top: var(--input-label-position-top-tablet);
			}

			@include bp-min(lg) {
				font-size: var(--input-label-font-size-desktop);
				top: var(--input-label-position-top-desktop);
			}

		}

		&.s {

			& ~ .label {

				top: var(--input-label-position-top-s-mobile);

				@include bp-min(sm) {
					top: var(--input-label-position-top-s-tablet);
				}

				@include bp-min(lg) {
					top: var(--input-label-position-top-s-desktop);
				}

			}

		}

	}

	& ~ .arrow {

		width: 12px;
		height: 12px;
		position: absolute;
		transform: translate(0, -50%);
		z-index: 3;

		top: var(--select-arrow-top-mobile);
		right: var(--select-arrow-right-mobile);

		@include bp-min(sm) {
			top: var(--select-arrow-top-tablet);
			right: var(--select-arrow-right-tablet);
		}

		@include bp-min(lg) {
			top: var(--select-arrow-top-desktop);
			right: var(--select-arrow-right-desktop);
		}

		svg {

			position: absolute;
			top: 0;
			left: 0;
			transition: var(--transition-default);

			&,
			* {
				fill: var(--select-arrow-color) !important;
			}

		}

	}

	&.s {

		& ~ .arrow {

			top: var(--select-arrow-top-s-mobile);
			right: var(--select-arrow-right-s-mobile);
			transform: translate(0, -50%);

			@include bp-min(sm) {
				top: var(--select-arrow-top-s-tablet);
				right: var(--select-arrow-right-s-tablet);
			}

			@include bp-min(lg) {
				top: var(--select-arrow-top-s-desktop);
				right: var(--select-arrow-right-s-desktop);
			}

		}

		& ~ .pseudoItems {

			padding-bottom: var(--select-items-padding-bottom-s);
			top: calc(var(--input-height-s-mobile) - var(--select-items-active-top));

			@include bp-min(sm) {
				top: calc(var(--input-height-s-tablet) - var(--select-items-active-top));
			}

			@include bp-min(lg) {
				top: calc(var(--input-height-s-desktop) - var(--select-items-active-top));
			}

			.pseudoItem {

				height: var(--select-item-height-s);
				font-size: var(--input-font-size-s-mobile);
				padding: var(--select-item-padding-s-mobile);

				@include bp-min(sm) {
					font-size: var(--input-font-size-s-tablet);
					padding: var(--select-item-padding-s-tablet);
				}

				@include bp-min(lg) {
					font-size: var(--input-font-size-s-desktop);
					padding: var(--select-item-padding-s-desktop);
				}

				&:not(:last-child):after {

					width: calc(100% - var(--select-item-divider-padding-s-mobile) * 2);

					@include bp-min(sm) {
						width: calc(100% - var(--select-item-divider-padding-s-tablet) * 2);
					}

					@include bp-min(lg) {
						width: calc(100% - var(--select-item-divider-padding-s-desktop) * 2);
					}

				}

			}

		}

	}

}

.default {
	color: transparent;
}

.label {
	z-index: 3;
}

.pseudoSelect {

	display: none;
	flex-direction: row;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	transition: none;
	color: var(--select-item-color);

	@include bp-min(sm) {

		display: flex;

		&.pseudoSelectOpen {
			border-bottom-color: transparent !important;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

	}

	&.pseudoSelectOpen {

		& ~ .arrow {
			svg {
				transform: rotate(180deg);
			}
		}

	}

}

.pseudoSelectValue {
	display: flex;
	flex-direction: row;
	align-items: stretch;
}

.pseudoSelectValueText {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.pseudoItemIcon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	transform: translate(0, 1px);
}

.pseudoItems {

	display: none;
	width: 100%;
	max-height: calc(var(--select-item-height) * 5);
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	position: absolute;
	left: 0;
	z-index: 999;
	background-color: var(--input-background-color);
	border: var(--input-border-width) var(--input-border-color-active) solid;
	border-top: none;
	border-bottom-color: var(--input-border-bottom-color);
	border-bottom-width: var(--input-border-bottom-width-active);
	border-bottom-left-radius: var(--input-border-radius);
	border-bottom-right-radius: var(--input-border-radius);
	padding-bottom: var(--select-items-padding-bottom);

	top: calc(var(--input-height-mobile) - var(--select-items-active-top));

	@include bp-min(sm) {
		top: calc(var(--input-height-tablet) - var(--select-items-active-top));
	}

	@include bp-min(lg) {
		top: calc(var(--input-height-desktop) - var(--select-items-active-top));
	}

	&.smallItemBox {
		max-height: calc(var(--select-item-height) * 2 + 15px);
	}

	&.pseudoItemsOpen {
		@include bp-min(sm) {
			display: block;
		}
	}

	&.error {
		border-bottom-color: var(--input-border-bottom-color-error);
		border-left-color: var(--input-border-left-color-error);
		border-right-color: var(--input-border-right-color-error);
	}

}

.pseudoItem {

	height: var(--select-item-height);
	cursor: pointer;
	background-color: transparent;
	color: var(--select-item-color);
	position: relative;
	display: flex;
	flex-direction: row;

	font-weight: var(--input-font-weight);
	font-size: var(--input-font-size-mobile);
	padding: var(--select-item-padding-mobile);

	@include bp-min(sm) {
		font-size: var(--input-font-size-tablet);
		padding: var(--select-item-padding-tablet);
	}

	@include bp-min(lg) {
		font-size: var(--input-font-size-desktop);
		padding: var(--select-item-padding-desktop);
	}

	&:not(:last-child):after {

		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		height: 1px;
		background-color: var(--select-item-divider-color);

		width: calc(100% - var(--select-item-divider-padding-mobile) * 2);

		@include bp-min(sm) {
			width: calc(100% - var(--select-item-divider-padding-tablet) * 2);
		}

		@include bp-min(lg) {
			width: calc(100% - var(--select-item-divider-padding-desktop) * 2);
		}

	}

	&.pseudoItemActive {

		background-color: var(--select-item-active-background-color);
		color: var(--select-item-active-color);

		& > div {
			color: var(--select-item-active-color);
		}

		&.pseudoItemCursor {
			background-color: var(--select-item-cursor-active-background-color);
			color: var(--select-item-cursor-active-color);
		}

	}

	&.pseudoItemCursor {
		background-color: var(--select-item-cursor-background-color);
		color: var(--select-item-cursor-color);
	}

	&:hover {

		background-color: var(--select-item-cursor-background-color);
		color: var(--select-item-cursor-color);

		&.pseudoItemActive {
			background-color: var(--select-item-cursor-active-background-color);
			color: var(--select-item-cursor-active-color);
		}

	}

}

.pseudoItemIcon {
	position: relative;
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
