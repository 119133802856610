@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

$closeIconSize: 15px;

.popup {
	position: fixed;
	z-index: 1000001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(111,119,121, 0.56);
	opacity: 0.000001;
	pointer-events: none;
	transition: opacity 0.2s;
}

.active {
	opacity: 1;
	pointer-events: all;
}

.closeLayer {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000002;
	cursor: pointer;
}

.closeIcon {
	width: $closeIconSize;
	height: $closeIconSize;
	position: absolute;
	top: 15px;
	right: 15px;
	cursor: pointer;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;

	svg {
		width: $closeIconSize;
		height: $closeIconSize;
	}
}

.box {

	position: absolute;
	z-index: 1000003;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-height: 90%;
	background-color: $primaryWhite;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 12px 14px -10px rgba(0, 0, 0, 0.19);

	padding: 10px 15px 70px;

	@include bp-min(sm) {
		padding: 20px 30px 80px;
	}

}

.size {
	&-sm {
		.box {

			max-width: calc(100% - 20px);
			padding: 10px 15px;

			@include bp-min(sm) {
				max-width: 400px;
				padding: 20px 30px;
			}

			&.hasFooterButton {

				padding: 10px 15px 65px;

				@include bp-min(sm) {
					padding: 20px 30px 80px;
				}

			}

		}
	}

	&-lg {
		.box {

			max-width: 90%;
			padding: 45px 10px 30px;
			height: 100%;

			@include bp-min(sm) {
				padding: 50px 15px;
				height: auto;
			}

			@include bp-min(lg) {
				max-width: 65%;
				padding: 60px 45px 45px;
			}

			.content {
				padding-left: 15px;
				padding-right: 15px;
				height: 100%;
				padding-bottom: 15px;
				position: relative;
				-ms-overflow-style: none;
				overflow: auto;
			}

		}
	}
}

.footerButton {

	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	button {
		width: 100%;
		border-radius: 0;
	}

}
