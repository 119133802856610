.cssIcon {

	width: 25px;
	height: 25px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);

	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

}
