@import 'assets/styles/mixins/_breakpoints.scss';
@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';

$transitionTime: 0.2s;

.wrapper {
	position: relative;
	width: 100%;
	border: 1px solid $secondaryTurquoise;
	border-radius: 6px;
	background-color: $primaryWhite;
	transition: $transitionTime;
	margin-bottom: 10px;
	box-shadow: 0 12px 14px -13px rgba(0, 0, 0, 0.11);
	outline: none;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;

	padding: 15px 15px;

	@include bp-min(sm) {
		max-width: 350px;
		&:not(:last-child) {
			margin-right: 5px;
		}
		&:not(:first-child) {
			margin-left: 5px;
		}
	}

	@include bp-min(md) {
		padding: 15px 20px;
	}

	@include bp-min(lg) {
		padding: 15px 30px;
	}

}

.content {

	padding-bottom: 80px;
	width: 100%;

	ul {

		padding-left: 0;

		li {

			color: $primaryGrey;
			list-style-type: none;
			padding-left: 25px;
			position: relative;

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			&:before {
				content: '';
				width: 12px;
				height: 12px;
				position: absolute;
				top: 6px;
				left: 0;
				background-size: contain;
				background-position: top left;
				background-repeat: no-repeat;
				background-image: url(../../../../assets/img/svg/svg_check_green.svg);
			}

		}
	}

	ol {
		padding-left: 40px;

		li {
			color: $primaryGrey;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

}

.stretch {
	align-self: stretch;
}

.active {
	border-width: 2px;
}

.headline {

	margin: 0;
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: $font-weight-light;
	position: relative;

	@include bp-min(sm) {
		font-size: 20px;
	}

}

.hasPrependIcon {
	padding-left: 35px;
	margin-bottom: 30px;
}

.hasAppendIcon {
	padding-right: 35px;
	margin-bottom: 30px;
}

.prependIcon {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -35%);
}

.appendIcon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -35%);
}

.toggleButton {
	display: inline-block;
	margin-top: 10px;
	margin-bottom: 20px;
	text-decoration: underline;
	position: relative;

	svg {
		* {
			fill: $linkColor !important;
		}
	}

}

.toggleButtonActive {
	svg {
		transform: rotate(180deg) !important;
	}
}

.check {
	position: absolute;
	left: 50%;
	bottom: 15px;
	transform: translate(-50%, 0);
}
