@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.box {

	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 15px;
	border-bottom: 2px solid $primarySky;

	@include bp-min(md) {
		margin-bottom: 30px;
	}

}

.col {

	position: relative;
	width: 33.3333%;
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include bp-min(md) {
		height: 100px;
	}

}

.label {

	font-size: 11px;
	color: $primaryBlack;

	@include bp-min(sm) {
		font-size: 13px;
	}

	@include bp-min(md) {
		font-size: 13px;
	}

	@include bp-min(lg) {
		font-size: 14px;
	}

}

.text {

	font-size: 11px;
	font-weight: 400;
	color: #000000;
	margin-top: 10px;

	@include bp-min(sm) {
		font-size: 13px;
	}

	@include bp-min(md) {
		font-size: 16px;
		font-weight: 300;
	}

	@include bp-min(lg) {
		font-size: 18px;
	}

}
