// Old
@font-face {
	font-family: 'SantanderText';
	src: url('../../fonts/SantanderText-Bold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/SantanderText-Bold.ttf') format('truetype'),
	url('../../fonts/SantanderText-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'SantanderText';
	src: url('../../fonts/SantanderText-Regular.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/SantanderText-Regular.woff') format('woff'),
	url('../../fonts/SantanderText-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'SantanderText';
	src: url('../../fonts/SantanderText-Light.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/SantanderText-Light.woff') format('woff'),
	url('../../fonts/SantanderText-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

// New
@font-face { font-family: 'Santander Icons';  src: url('../../fonts/SantanderIcons.woff') format('woff');  }

@font-face { font-family: 'Santander MicroText';  src: url('../../fonts/SantanderMicroTextW05-Rg.woff') format('woff'); font-weight: 400;  }
@font-face { font-family: 'Santander MicroText';  src: url('../../fonts/SantanderMicroTextW05-Bold.woff') format('woff'); font-weight: 700;  }

@font-face { font-family: 'Santander Headline';  src: url('../../fonts/SantanderHeadlineW05-Bold.woff') format('woff'); font-weight: 700;  }
@font-face { font-family: 'Santander Headline';  src: url('../../fonts/SantanderHeadlineW05-Rg.woff') format('woff'); font-weight: 400;  }
@font-face { font-family: 'Santander Headline';  src: url('../../fonts/SantanderHeadlineW05-Light.woff') format('woff'); font-weight: 300;  }
