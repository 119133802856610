$transitionTime: 0.2s;

.rangeslider-horizontal {
	height: 2px;
}

.rangeslider,
.rangeslider .rangeslider__fill {
	box-shadow: none;
	outline: none;
	* {
		box-shadow: none;
		outline: none;
	}
}

.rangeslider {
	background-color: $primaryGreyLight;
}

.rangeslider .rangeslider__fill {
	background-color: $secondaryTurquoise;
}

.rangeslider .rangeslider__handle {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: none;
	box-shadow: none;
	background-color: $secondaryTurquoise;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: background-color $transitionTime ease-in-out !important;
}

.rangeslider .rangeslider__handle:after {
	display: none;
}

.rangeslider--focus {
	.rangeslider .rangeslider__handle {
		background-color: $primarySkyDark;
	}
}
