@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.message {

	position: relative;
	background-color: $secondaryGreenLight;
	color: $secondaryGreenDark;
	margin-bottom: 20px;
	border-radius: 2px;
	padding: 10px 45px 10px 15px;
	font-size: 12px;
	line-height: $line-height-default;

	&.outline {
		border: 1px solid $secondaryGreenDark;
	}

	* {
		color: $secondaryGreenDark;
	}

	@include bp-min(sm) {
		font-size: 14px;
	}

	.text {
		display: block;
	}

	.icon {
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translate(0, -50%);
	}

}
