@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.wrapper {

	position: relative;
	min-height: 50px;

	&:before {
		content: '';
		width: 100%;
		height: 1px;
		background-color: $primarySky;
		position: absolute;
		top: 0;
		left: 0;
	}

}

.loading {
	width: 100%;
	text-align: center;
	padding: 30px;
}

.inner {
	width: 100%;
}

.item {

	font-size: 10px;
	line-height: 1.2;
	padding: 5px 10px;

	@include bp-min(sm) {
		font-size: 10px;
		padding: 10px 15px;
	}

	@include bp-min(lg) {
		padding: 15px 20px;
	}

}

.footer {

	position: relative;
	width: 100%;
	padding: 5px 10px;

	@include bp-min(sm) {
		display: flex;
		flex-direction: row;
		padding: 10px 15px;
	}

	@include bp-min(lg) {
		padding: 15px 20px;
	}

	&:before {
		content: '';
		width: 100%;
		height: 1px;
		background-color: $primarySky;
		position: absolute;
		top: 0;
		left: 0;
	}

}

.footerItem {
	&:not(:last-child) {
		margin-bottom: 10px;
		@include bp-min(sm) {
			margin-bottom: 0;
		}
	}
}

.footerItemInner {
	display: flex;
	&:not(:last-child) {
		margin-bottom: 10px;
	}

	&.contentRight {
		justify-content: flex-end;
	}

}

.footerItemSpacer {
	flex-grow: 1;
	display: none;
	@include bp-min(sm) {
		display: block;
	}
}

.textSpacer {
	height: 10px;
}
