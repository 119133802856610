:root {
	--flame-color-primary-santander: #ec0000;
	--flame-color-primary-boston: #cc0000;
	--flame-color-primary-ruby: #990000;
	--flame-color-primary-dark-grey: #444444;
	--flame-color-primary-darker-grey: #222222;
	--flame-color-primary-medium-grey: #767676;
	--flame-color-primary-light-grey: #cccccc;
	--flame-color-primary-lighter-grey: #f0f0f0;
	--flame-color-primary-light-sky: #deedf2;
	--flame-color-primary-medium-sky: #cedee7;
	--flame-color-primary-dark-sky: #9bc3d3;
	--flame-color-primary-white: #ffffff;
	--flame-color-primary-black: #000000;
	--flame-color-secondary-yellow: #ffcc33;
	--flame-color-secondary-green: #63ba68;
	--flame-color-secondary-turquoise: #1bb3bc;
	--flame-color-secondary-blue: #3366ff;
	--flame-color-secondary-purple: #9e3667;
	--flame-color-secondary-lighter-sky: #f5f9fb;
	--flame-color-secondary-accessible-dark-yellow: #946f00;
	--flame-color-secondary-accessible-dark-green: #008437;
	--flame-color-secondary-accessible-dark-turquoise: #137e84;
	--flame-color-secondary-accessible-dark-blue: #0032e6;
	--flame-color-secondary-accessible-dark-purple: #732645;
	--flame-color-secondary-accessible-dark-sky: #257fa4;
	--flame-color-neutral-10: #f6f6f6;
	--flame-color-neutral-20: #ececec;
	--flame-color-neutral-30: #dadada;
	--flame-color-neutral-40: #c7c7c7;
	--flame-color-neutral-50: #b5b5b5;
	--flame-color-neutral-60: #a2a2a2;
	--flame-color-neutral-70: #8f8f8f;
	--flame-color-neutral-80: #7c7c7c;
	--flame-color-neutral-90: #6a6a6a;
	--flame-color-neutral-100: #575757;
	--flame-color-support-error-base: #cc0000;
	--flame-color-support-error-darker: #990000;
	--flame-color-support-error-lighter: #fee5e5;
	--flame-color-support-success-base: #63ba68;
	--flame-color-support-success-darker: #3a8340;
	--flame-color-support-success-lighter: #f0f8f0;
	--flame-color-support-warning-base: #ffcc33;
	--flame-color-support-warning-darker: #946f00;
	--flame-color-support-warning-lighter: #fffaeb;
	--flame-color-support-info-base: #9bc3d3;
	--flame-color-support-info-darker: #257fa4;
	--flame-color-support-info-lighter: #f9fcfd;
	--flame-color-tint-santander-05: #fff2f2;
	--flame-color-tint-santander-10: #fee5e5;
	--flame-color-tint-santander-20: #fccccc;
	--flame-color-tint-santander-30: #fab2b2;
	--flame-color-tint-santander-40: #f89999;
	--flame-color-tint-santander-50: #f67f7f;
	--flame-color-tint-santander-60: #f46666;
	--flame-color-tint-santander-70: #f24c4c;
	--flame-color-tint-santander-80: #f03333;
	--flame-color-tint-santander-90: #ee1919;
	--flame-color-tint-yellow-10: #fffaeb;
	--flame-color-tint-yellow-20: #fff5d7;
	--flame-color-tint-yellow-30: #fff0c2;
	--flame-color-tint-yellow-40: #ffebae;
	--flame-color-tint-yellow-50: #ffe699;
	--flame-color-tint-yellow-60: #ffe185;
	--flame-color-tint-yellow-70: #ffdc70;
	--flame-color-tint-yellow-80: #ffd75c;
	--flame-color-tint-yellow-90: #ffd147;
	--flame-color-tint-yellow-05: #fffdf5;
	--flame-color-tint-purple-10: #f6ebf0;
	--flame-color-tint-purple-20: #ecd7e1;
	--flame-color-tint-purple-30: #e2c3d2;
	--flame-color-tint-purple-40: #d9afc3;
	--flame-color-tint-purple-50: #cf9bb3;
	--flame-color-tint-purple-60: #c587a4;
	--flame-color-tint-purple-70: #bb7295;
	--flame-color-tint-purple-80: #b25f86;
	--flame-color-tint-purple-90: #a84a76;
	--flame-color-tint-purple-05: #fbf5f8;
	--flame-color-tint-blue-10: #ebf0ff;
	--flame-color-tint-blue-20: #d7e1ff;
	--flame-color-tint-blue-30: #c2d1ff;
	--flame-color-tint-blue-40: #aec2ff;
	--flame-color-tint-blue-50: #99b3ff;
	--flame-color-tint-blue-60: #85a4ff;
	--flame-color-tint-blue-70: #7094ff;
	--flame-color-tint-blue-80: #5c85ff;
	--flame-color-tint-blue-90: #4775ff;
	--flame-color-tint-blue-05: #f5f8ff;
	--flame-color-tint-green-10: #f0f8f0;
	--flame-color-tint-green-20: #e0f2e1;
	--flame-color-tint-green-30: #d0ebd2;
	--flame-color-tint-green-40: #c1e4c3;
	--flame-color-tint-green-50: #b1ddb4;
	--flame-color-tint-green-60: #a2d6a5;
	--flame-color-tint-green-70: #92cf96;
	--flame-color-tint-green-80: #83c887;
	--flame-color-tint-green-90: #73c177;
	--flame-color-tint-green-05: #f8fcf8;
	--flame-color-tint-turquoise-10: #e8f8f9;
	--flame-color-tint-turquoise-20: #d2f0f2;
	--flame-color-tint-turquoise-30: #bbe9eb;
	--flame-color-tint-turquoise-40: #a4e1e5;
	--flame-color-tint-turquoise-50: #8dd9de;
	--flame-color-tint-turquoise-60: #77d2d7;
	--flame-color-tint-turquoise-70: #5fcad0;
	--flame-color-tint-turquoise-80: #49c3ca;
	--flame-color-tint-turquoise-90: #32bbc3;
	--flame-color-tint-turquoise-05: #f3fcfc;
	--flame-color-tint-sky-10: #fcfefe;
	--flame-color-tint-sky-20: #f9fcfd;
	--flame-color-tint-sky-30: #f6fafc;
	--flame-color-tint-sky-40: #f2f8fa;
	--flame-color-tint-sky-50: #eff6f9;
	--flame-color-tint-sky-60: #ecf5f8;
	--flame-color-tint-sky-70: #e8f3f6;
	--flame-color-tint-sky-80: #e5f1f5;
	--flame-color-tint-sky-90: #e2eff4;
}

:root {
	--flame-line-height-01: 1rem;
	--flame-line-height-02: 1.25rem;
	--flame-line-height-03: 1.5rem;
	--flame-line-height-04: 1.5rem;
	--flame-line-height-05: 1.5rem;
	--flame-line-height-06: 2rem;
	--flame-line-height-07: 2rem;
	--flame-line-height-08: 2.25rem;
	--flame-line-height-09: 2.75rem;
	--flame-line-height-10: 3.25rem;
	--flame-line-height-11: 3.75rem;
	--flame-line-height-12: 4rem;
	--flame-line-height-13: 4.5rem;
}

:root {
	--flame-spacing-0: 2px;
	--flame-spacing-1: 4px;
	--flame-spacing-2: 8px;
	--flame-spacing-3: 12px;
	--flame-spacing-4: 16px;
	--flame-spacing-5: 24px;
	--flame-spacing-6: 32px;
	--flame-spacing-7: 40px;
	--flame-spacing-8: 48px;
	--flame-spacing-9: 56px;
	--flame-spacing-10: 64px;
	--flame-spacing-11: 72px;
	--flame-spacing-12: 80px;
	--flame-spacing-13: 88px;
	--flame-spacing-14: 96px;
}

:root {
	--flame-font-family-headline: "Santander Headline", monospace;
	--flame-font-family-paragraph: "Santander MicroText", monospace;
	--flame-font-size-01: 0.75rem;
	--flame-font-size-02: 0.875rem;
	--flame-font-size-03: 1rem;
	--flame-font-size-04: 1.125rem;
	--flame-font-size-05: 1.25rem;
	--flame-font-size-06: 1.5rem;
	--flame-font-size-07: 1.75rem;
	--flame-font-size-08: 2rem;
	--flame-font-size-09: 2.25rem;
	--flame-font-size-10: 2.625rem;
	--flame-font-size-11: 3rem;
	--flame-font-size-12: 3.375rem;
	--flame-font-size-13: 3.75rem;
	--flame-font-size-default-desktop: var(--flame-font-size-03);
	--flame-font-size-default-mobile: var(--flame-font-size-02);
	--flame-line-height-default: var(--flame-line-height-4);
	--flame-font-header-60: var(--flame-font-size-13)/var(--flame-line-height-13) var(--flame-font-family-headline);
	--flame-font-header-54: var(--flame-font-size-12)/var(--flame-line-height-12) var(--flame-font-family-headline);
	--flame-font-header-48: var(--flame-font-size-11)/var(--flame-line-height-11) var(--flame-font-family-headline);
	--flame-font-header-42: var(--flame-font-size-10)/var(--flame-line-height-10) var(--flame-font-family-headline);
	--flame-font-header-36: var(--flame-font-size-09)/var(--flame-line-height-09) var(--flame-font-family-headline);
	--flame-font-header-32: var(--flame-font-size-08)/var(--flame-line-height-08) var(--flame-font-family-headline);
	--flame-font-header-28: var(--flame-font-size-07)/var(--flame-line-height-07) var(--flame-font-family-headline);
	--flame-font-header-24: var(--flame-font-size-06)/var(--flame-line-height-06) var(--flame-font-family-headline);
	--flame-font-header-20: var(--flame-font-size-05)/var(--flame-line-height-05) var(--flame-font-family-headline);
	--flame-font-paragraph-18: var(--flame-font-size-04)/var(--flame-line-height-04) var(--flame-font-family-paragraph);
	--flame-font-paragraph-16: var(--flame-font-size-03)/var(--flame-line-height-03) var(--flame-font-family-paragraph);
	--flame-font-paragraph-14: var(--flame-font-size-02)/var(--flame-line-height-02) var(--flame-font-family-paragraph);
	--flame-font-paragraph-12: var(--flame-font-size-01)/var(--flame-line-height-01) var(--flame-font-family-paragraph);
}

:root {
	--flame-shape-rounded-xs: 4px;
}

:root {
	--flame-elevation-00: 0 0 0 0 rgba(0, 0, 0, 0);
	--flame-elevation-01: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
	--flame-elevation-02: 0 3px 4px 0 rgba(0, 0, 0, 0.12);
	--flame-elevation-03: 0 5px 8px 0 rgba(0, 0, 0, 0.12);
	--flame-elevation-04: 0 16px 32px -12px rgba(0, 0, 0, 0.16);
	--flame-elevation-05: 0 32px 32px -12px rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 375px) {
	:root {
		--flame-font-header-60: var(--flame-font-size-10)/var(--flame-line-height-10) var(--flame-font-family-headline);
		--flame-font-header-54: var(--flame-font-size-09)/var(--flame-line-height-09) var(--flame-font-family-headline);
		--flame-font-header-48: var(--flame-font-size-08)/var(--flame-line-height-08) var(--flame-font-family-headline);
		--flame-font-header-42: var(--flame-font-size-08)/var(--flame-line-height-08) var(--flame-font-family-headline);
		--flame-font-header-36: var(--flame-font-size-08)/var(--flame-line-height-08) var(--flame-font-family-headline);
		--flame-font-header-32: var(--flame-font-size-07)/var(--flame-line-height-07) var(--flame-font-family-headline);
		--flame-font-header-28: var(--flame-font-size-07)/var(--flame-line-height-06) var(--flame-font-family-headline);
		--flame-font-header-24: var(--flame-font-size-05)/var(--flame-line-height-05) var(--flame-font-family-headline);
		--flame-font-header-20: var(--flame-font-size-04)/var(--flame-line-height-05) var(--flame-font-family-headline);
		--flame-font-paragraph-18: var(--flame-font-size-03)/var(--flame-line-height-03) var(--flame-font-family-paragraph);
		--flame-font-paragraph-16: var(--flame-font-size-03)/var(--flame-line-height-02) var(--flame-font-family-paragraph);
		--flame-font-paragraph-14: var(--flame-font-size-03)/var(--flame-line-height-02) var(--flame-font-family-paragraph);
		--flame-font-paragraph-12: var(--flame-font-size-03)/var(--flame-line-height-02) var(--flame-font-family-paragraph);
	}
}
