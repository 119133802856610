@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.wrapper {
	position: absolute;
	top: 60px;
	left: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-size: 8px;
	padding: 5px 15px 6px 10px;
	line-height: 1.5;
	border-radius: 25px;
	color: $primaryGreyBlue;
	background-color: $primarySky;
	border: 1px solid $extraSky;
	transform: translate(-50%, 0);
	@include bp-min(sm) {
		top: 80px;
		left: 0;
		transform: none;
		font-size: 12px;
	}
}

.text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 5px;
	color: $primaryGreyBlue;
	align-self: stretch;
}

.icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: stretch;
	svg {
		width: 10px;
		height: 10px;

		@include bp-min(sm) {
			width: 15px;
			height: 15px;
		}
	}
}
