$breakpoints: null, xs, sm, md, lg, xl, xxl, xxxl, xxxxl;

// Min
$xs-min: 0;
$sm-min: 576px;
$md-min: 768px;
$lg-min: 992px;
$xl-min: 1200px;
$xxl-min: 1400px;
$xxxl-min: 1600px;
$xxxxl-min: 1920px;

// Max
$xs-max: $sm-min - 1;
$sm-max: $md-min - 1;
$md-max: $lg-min - 1;
$lg-max: $xl-min - 1;
$xl-max: $xxl-min - 1;
$xxl-max: $xxxl-min - 1;
$xxxl-max: $xxxxl-min - 1;

@function getBpMin ($breakpoint) {
	$min: null;
	@if $breakpoint == xs {
		$min: $xs-min;
	} @else if $breakpoint == sm {
		$min: $sm-min;
	} @else if $breakpoint == md {
		$min: $md-min;
	} @else if $breakpoint == lg {
		$min: $lg-min;
	} @else if $breakpoint == xl {
		$min: $xl-min;
	} @else if $breakpoint == xxl {
		$min: $xxl-min;
	} @else if $breakpoint == xxxl {
		$min: $xxxl-min;
	} @else if $breakpoint == xxxxl {
		$min: $xxxxl-min;
	}
	@return $min;
}

@function getBpMax ($breakpoint) {
	$max: null;
	@if $breakpoint == xs {
		$max: $xs-max;
	} @else if $breakpoint == sm {
		$max: $sm-max;
	} @else if $breakpoint == md {
		$max: $md-max;
	} @else if $breakpoint == lg {
		$max: $lg-max;
	} @else if $breakpoint == xl {
		$max: $xl-max;
	} @else if $breakpoint == xxl {
		$max: $xxl-max;
	} @else if $breakpoint == xxxl {
		$max: $xxxl-max;
	}
	@return $max;
}

@mixin bp($breakpoint) {
	@if $breakpoint {
		$min: getBpMin($breakpoint);
		$max: getBpMax($breakpoint);
		@if $min and $max {
			@media (min-width: #{$min}) and (max-width: #{$max}) {
				@content;
			}
		} @else {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin bp-min($breakpoint) {
	@if $breakpoint {
		$min: getBpMin($breakpoint);
		@if $min {
			@media (min-width: #{$min}) {
				@content;
			}
		} @else {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin bp-max($breakpoint) {
	@if $breakpoint {
		$max: getBpMax($breakpoint);
		@if $max {
			@media (max-width: #{$max}) {
				@content;
			}
		} @else {
			@content;
		}
	} @else {
		@content;
	}
}
