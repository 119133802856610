@import-normalize;

@import '~react-rangeslider/lib/index.css';

@import 'setup/form';
@import 'setup/variables';
@import 'setup/defaults';
@import 'setup/font-families';

// Variables
@import 'variables/colors';
@import 'variables/fonts';

// Mixins
@import 'mixins/breakpoints';

// external
@import 'external/tooltip';
@import 'external/rangeslider';

// Layout
@import 'layout';
@import 'color';
@import 'font';
