@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';

.wrapper {

	margin-top: 15px;
	margin-bottom: 30px;
	outline: none;

	&.hasFocus {background-color: red;
		.rangeslider__fill {
			background-color: red;
		}
	}

}

.row {
	display: flex;
	flex-direction: row;
}

.label,
.value {
	font-size: 14px;
	font-weight: $font-weight-light;
	color: $primaryBlack;
}

.label {
	flex-grow: 1;
}

.value {
	flex-grow: 0;
}

.highlightLabel {
	.label {
		font-size: 18px;
		font-weight: $font-weight-bold;
	}

	.value {
		font-size: 22px;
		font-weight: $font-weight-bold;
		color: $secondaryTurquoise;
	}
}

.focus {

	.rangeslider .rangeslider__fill {
		background-color: $primarySkyDark;
	}

}
