@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.divider {
	width: 100%;
	height: 1px;
	margin-top: 5px;
	margin-bottom: 5px;

	&:not(.noMarginTop) {
		margin-top: 20px;
	}

	&:not(.noMarginBottom) {
		margin-bottom: 20px;
	}

	&:not(.noLine) {
		background-color: $primarySkyMedium;
	}

}

.dividerMobile {
	@include bp-min(sm) {
		display: none;
	}
}
