:root {
	// General
	--transition-default-time: 0.2s;
	--transition-default: all var(--transition-default-time) ease-in-out;
	--font-family: 'SantanderText', sans-serif;
	--font-family-headline: 'SantanderText', sans-serif;
	--icon-default-size: 24px;

	// Color
	--primary-color: var(--flame-color-secondary-purple);
	--primary-color-active: var(--flame-color-secondary-accessible-dark-purple);
	--primary-color-light: var(--flame-color-tint-purple-10);
	--primary-color-light-2: var(--flame-color-tint-purple-20);
	--secondary-color: var(--flame-color-secondary-turquoise);
	--secondary-color-active: var(--flame-color-primary-dark-sky);

	// Link
	--link-primary-color: var(--primary-color);
	--link-primary-color-active: var(--primary-color-active);
	--link-primary-text-decoration: none;
	--link-primary-text-decoration-active: none;
	--link-primary-font-weight: bold;

	--link-light-color: var(--flame-color-primary-medium-grey);
	--link-light-color-active: var(--flame-color-primary-medium-grey);
	--link-light-text-decoration: none;
	--link-light-text-decoration-active: none;
	--link-light-font-weight: normal;

	// Buttons
	--button-primary-background-color: var(--primary-color);
	--button-primary-border-color: var(--button-primary-background-color);
	--button-primary-background-color-active: #B9638B;
	--button-primary-border-color-active: #B9638B;
	--button-primary-background-color-hover: #B9638B;
	--button-primary-border-color-hover: #B9638B;
	--button-primary-text-color: var(--flame-color-primary-white);

	--button-secondary-background-color: #deedf2;
	--button-secondary-border-color: var(--button-secondary-background-color);
	--button-secondary-background-color-active: #9BC3D3;
	--button-secondary-border-color-active: var(--button-secondary-background-color-active);
	--button-secondary-background-color-hover: var(--button-secondary-background-color-active);
	--button-secondary-border-color-hover: var(--button-secondary-background-color-hover);
	--button-secondary-text-color: #63818e;

	--button-link-text-color: var(--link-primary-color);
	--button-link-text-color-active: var(--link-primary-color-active);
	--button-link-text-color-hover: var(--link-primary-color-active);

	--button-disabled-background-color: var(--flame-color-primary-light-grey);
	--button-disabled-border-color: var(--flame-color-primary-light-grey);
	--button-disabled-text-color: var(--flame-color-primary-white);

	--button-border-radius: 3px;

	--button-height-desktop: 50px;
	--button-height-tablet: var(--button-height-desktop);
	--button-height-mobile: var(--button-height-tablet);

	--button-fonts-size-desktop: 12px;
	--button-fonts-size-tablet: 12px;
	--button-fonts-size-mobile: 12px;

	--button-font-weight: 700;
	--button-text-transform: uppercase;
	--button-letter-spacing: 1.2px;

	--button-box-shadow: none;

	--button-padding-s-desktop: var(--flame-spacing-1) var(--flame-spacing-3);
	--button-padding-s-tablet: var(--button-padding-s-desktop);
	--button-padding-s-mobile: var(--button-padding-s-tablet);

	--button-padding-m-desktop: var(--flame-spacing-4) var(--flame-spacing-8);
	--button-padding-m-tablet: var(--flame-spacing-3) var(--flame-spacing-7);
	--button-padding-m-mobile: var(--flame-spacing-3) var(--flame-spacing-5);

	--button-padding-l-desktop: var(--flame-spacing-3) 20px;
	--button-padding-l-tablet: var(--button-padding-l-desktop);
	--button-padding-l-mobile: var(--button-padding-l-tablet);

	// Form
	--input-error-color: var(--flame-color-support-error-base);
	--input-error-message-color: var(--input-error-color);

	--input-label-font-weight: 400;

	--input-label-font-size-desktop: var(--flame-font-size-03);
	--input-label-font-size-tablet: var(--input-label-font-size-desktop);
	--input-label-font-size-mobile: var(--input-label-font-size-tablet);

	--input-label-font-size-s-desktop: 10px;
	--input-label-font-size-s-tablet: var(--input-label-font-size-s-desktop);
	--input-label-font-size-s-mobile: var(--input-label-font-size-s-tablet);

	--input-label-font-size-desktop-active: var(--flame-font-size-01);
	--input-label-font-size-tablet-active: var(--input-label-font-size-desktop-active);
	--input-label-font-size-mobile-active: 12px;

	--input-label-font-size-s-desktop-active: var(--input-label-font-size-s-desktop);
	--input-label-font-size-s-tablet-active: var(--input-label-font-size-s-desktop-active);
	--input-label-font-size-s-mobile-active: var(--input-label-font-size-s-tablet-active);

	--input-label-position-top-desktop: var(--flame-spacing-3);
	--input-label-position-top-tablet: var(--input-label-position-top-desktop);
	--input-label-position-top-mobile: var(--input-label-position-top-tablet);

	--input-label-position-top-s-desktop: 5px;
	--input-label-position-top-s-tablet: var(--input-label-position-top-s-desktop);
	--input-label-position-top-s-mobile: var(--input-label-position-top-s-tablet);

	--input-label-position-top-desktop-active: var(--flame-spacing-1);
	--input-label-position-top-tablet-active: var(--input-label-position-top-desktop-active);
	--input-label-position-top-mobile-active: var(--input-label-position-top-tablet-active);

	--input-label-position-top-s-desktop-active: var(--input-label-position-top-s-desktop);
	--input-label-position-top-s-tablet-active: var(--input-label-position-top-s-desktop-active);
	--input-label-position-top-s-mobile-active: var(--input-label-position-top-s-tablet-active);

	--input-label-position-left-desktop: var(--flame-spacing-4);
	--input-label-position-left-tablet: var(--input-label-position-left-desktop);
	--input-label-position-left-mobile: var(--input-label-position-left-tablet);

	--input-label-position-left-s-desktop: 10px;
	--input-label-position-left-s-tablet: var(--input-label-position-left-s-desktop);
	--input-label-position-left-s-mobile: var(--input-label-position-left-s-tablet);

	--input-label-color: var(--flame-color-primary-medium-grey);
	--input-label-color-active: var(--flame-color-secondary-turquoise);
	--input-label-color-disabled: var(--flame-color-primary-light-grey);
	--input-label-color-error: var(--input-error-message-color);

	--input-message-font: var(--flame-font-paragraph-12);
	--input-message-font-mobile: 12px/var(--flame-line-height-01) var(--flame-font-family-paragraph);
	--input-message-color: var(--flame-color-primary-dark-grey);

	--input-tooltip-top: 13px;
	--input-tooltip-right: 15px;

	--input-suffix-font-size: var(--input-font-size-desktop);
	--input-suffix-font-weight: 400;
	--input-suffix-right: 15px;
	--input-suffix-right-tooltip: 50px;

	--input-suffix-top-desktop: 16px;
	--input-suffix-top-tablet: var(--input-suffix-top-desktop);
	--input-suffix-top-mobile: var(--input-suffix-top-tablet);

	// Input
	--input-height-desktop: 48px;
	--input-height-tablet: var(--input-height-desktop);
	--input-height-mobile: var(--input-height-tablet);

	--input-height-s-desktop: 44px;
	--input-height-s-tablet: var(--input-height-s-desktop);
	--input-height-s-mobile: var(--input-height-s-tablet);

	--input-padding-desktop: 20px var(--flame-spacing-4) var(--flame-spacing-1) var(--flame-spacing-4);
	--input-padding-tablet: var(--input-padding-desktop);
	--input-padding-mobile: var(--input-padding-tablet);

	--input-padding-s-desktop: 8px 8px 0 8px;
	--input-padding-s-tablet: var(--input-padding-s-desktop);
	--input-padding-s-mobile: var(--input-padding-s-tablet);

	--input-border-radius: var(--flame-shape-rounded-xs) var(--flame-shape-rounded-xs) 0 0;

	--input-font-size-desktop: 16px;
	--input-font-size-tablet: var(--input-font-size-desktop);
	--input-font-size-mobile: var(--input-font-size-tablet);

	--input-font-size-s-desktop: 14px;
	--input-font-size-s-tablet: var(--input-font-size-s-desktop);
	--input-font-size-s-mobile: var(--input-font-size-s-tablet);

	--input-font-weight: 400;

	--input-border-style: solid;

	--input-border-color: var(--flame-color-support-info-base);
	--input-border-color-active: var(--flame-color-secondary-turquoise);
	--input-border-color-error: var(--input-error-message-color);

	--input-border-top-color: var(--input-border-color);
	--input-border-bottom-color: var(--flame-color-secondary-turquoise);
	--input-border-left-color: var(--input-border-color);
	--input-border-right-color: var(--input-border-color);

	--input-border-top-color-active: var(--input-border-color-active);
	--input-border-bottom-color-active: var(--input-border-bottom-color);
	--input-border-left-color-active: var(--input-border-color-active);
	--input-border-right-color-active: var(--input-border-color-active);

	--input-border-top-color-error: var(--input-border-color-error);
	--input-border-bottom-color-error: var(--input-border-color-error);
	--input-border-left-color-error: var(--input-border-color-error);
	--input-border-right-color-error: var(--input-border-color-error);

	--input-border-width: 1px;
	--input-border-top-width: var(--input-border-width);
	--input-border-bottom-width: var(--input-border-width);
	--input-border-left-width: var(--input-border-width);
	--input-border-right-width: var(--input-border-width);
	--input-border-top-width-active: var(--input-border-width);
	--input-border-bottom-width-active: 2px;
	--input-border-left-width-active: var(--input-border-width);
	--input-border-right-width-active: var(--input-border-width);

	--input-color: var(--flame-color-primary-dark-grey);
	--input-background-color: var(--flame-color-primary-white);
	--input-disabled-color: var(--flame-color-primary-light-grey);
	--input-disabled-border-color: var(--flame-color-primary-light-grey);
	--input-disabled-background-color: var(--flame-color-neutral-10);

	--input-box-shadow: none;

	// Select
	--select-arrow-top-desktop: calc(var(--input-height-desktop) / 2);
	--select-arrow-top-tablet: calc(var(--input-height-tablet) / 2);
	--select-arrow-top-mobile: calc(var(--input-height-mobile) / 2);

	--select-arrow-top-s-desktop: calc(var(--input-height-s-desktop) / 2);
	--select-arrow-top-s-tablet:  calc(var(--input-height-s-tablet) / 2);
	--select-arrow-top-s-mobile:  calc(var(--input-height-s-mobile) / 2);

	--select-arrow-right-desktop: var(--flame-spacing-4);
	--select-arrow-right-tablet: var(--flame-spacing-4);
	--select-arrow-right-mobile: var(--flame-spacing-4);

	--select-arrow-right-s-desktop: 15px;
	--select-arrow-right-s-tablet: var(--select-arrow-right-s-desktop);
	--select-arrow-right-s-mobile: var(--select-arrow-right-s-tablet);

	--select-arrow-color: var(--secondary-color);

	--select-items-active-top: 0;
	--select-items-padding-bottom: 15px;
	--select-items-padding-bottom-s: 5px;

	--select-item-padding-desktop: var(--flame-spacing-2) var(--flame-spacing-4);
	--select-item-padding-tablet: var(--flame-spacing-2) var(--flame-spacing-4);
	--select-item-padding-mobile: var(--flame-spacing-2) var(--flame-spacing-4);

	--select-item-padding-s-desktop: 8px;
	--select-item-padding-s-tablet: var(--select-item-padding-s-desktop);
	--select-item-padding-s-mobile: var(--select-item-padding-s-tablet);

	--select-item-color: var(--input-color);

	--select-item-hover-background-color: var(--flame-color-primary-white);
	--select-item-hover-color: var(--primary-color);

	--select-item-active-background-color: var(--secondary-color);
	--select-item-active-color: var(--flame-color-primary-white);

	--select-item-cursor-background-color: var(--flame-color-primary-light-sky);
	--select-item-cursor-color: var(--select-item-color);
	--select-item-cursor-active-background-color: var(--secondary-color);
	--select-item-cursor-active-color: var(--select-item-active-color);

	--select-item-height: 38px;
	--select-item-height-s: 38px;

	--select-item-divider-padding-desktop: var(--flame-spacing-4);
	--select-item-divider-padding-tablet: var(--flame-spacing-4);
	--select-item-divider-padding-mobile: var(--flame-spacing-4);

	--select-item-divider-padding-s-desktop: 8px;
	--select-item-divider-padding-s-tablet: var(--select-item-divider-padding-s-desktop);
	--select-item-divider-padding-s-mobile: var(--select-item-divider-padding-s-mobile);

	--select-item-divider-color: transparent;

	// Checkbox
	--checkbox-label-size-desktop: var(--text-font-size-s-desktop);
	--checkbox-label-size-tablet: var(--text-font-size-s-tablet);
	--checkbox-label-size-mobile: var(--text-font-size-s-mobile);

	--checkbox-label-color: var(--flame-color-primary-black);

	--checkbox-label-top: 2px;

	--checkbox-box-size: 1.5rem;

	--checkbox-box-spacing: 15px;

	--checkbox-box-border-radius: var(--flame-shape-rounded-xs);
	--checkbox-box-border-width: 1px;
	--checkbox-box-border-width-checked: 1px;
	--checkbox-box-border-width-disabled: 1px;
	--checkbox-box-border-width-focus: 1px;

	--checkbox-box-background-color: var(--flame-color-primary-white);
	--checkbox-box-background-color-checked: var(--input-border-color-active);
	--checkbox-box-background-color-checked-focus: var(--flame-color-tint-turquoise-60);
	--checkbox-box-background-color-focus: var(--checkbox-box-background-color);
	--checkbox-box-background-color-disabled: var(--flame-color-primary-lighter-grey);

	--checkbox-box-border-color: var(--input-border-color);
	--checkbox-box-border-color-checked: var(--checkbox-box-background-color-checked);
	--checkbox-box-border-color-checked-focus: var(--checkbox-box-background-color-checked-focus);
	--checkbox-box-border-color-focus: var(--checkbox-box-background-color-checked);
	--checkbox-box-border-color-disabled: var(--input-border-color-disabled);

	--checkbox-box-checkmark-color-checked: var(--flame-color-primary-white);
	--checkbox-box-checkmark-color-disabled: var(--flame-color-primary-light-grey);

	// Radio-Button
	--radio-button-label-font: var(--input-label-font);
	--radio-button-border-radius: var(--flame-shape-rounded-xs);

	--radio-button-height-m-desktop: var(--button-height-desktop);
	--radio-button-height-m-tablet: var(--button-height-tablet);
	--radio-button-height-m-mobile: var(--button-height-mobile);

	--radio-button-height-s-desktop: 35px;
	--radio-button-height-s-tablet: 35px;
	--radio-button-height-s-mobile: 35px;

	--radio-button-padding-m-desktop: 0 var(--flame-spacing-4);
	--radio-button-padding-m-tablet: 0 var(--flame-spacing-4);
	--radio-button-padding-m-mobile: 0 var(--flame-spacing-4);

	--radio-button-padding-s-desktop: 0 var(--flame-spacing-3);
	--radio-button-padding-s-tablet: 0 var(--flame-spacing-3);
	--radio-button-padding-s-mobile: 0 var(--flame-spacing-3);

	--radio-button-size-desktop: 25px;
	--radio-button-size-tablet: 25px;
	--radio-button-size-mobile: 25px;

	--radio-button-border-width: 1px;
	--radio-button-border-width-active: 2px;
	--radio-button-color: var(--secondary-color);

	// Radio-Button: Default-Style
	--radio-button-default-label-space: 15px;
	--radio-button-default-label-color: var(--input-label-color);

	// Radio-Button: Button-Style
	--radio-button-button-background-color: var(--flame-color-primary-white);
	--radio-button-button-background-color-active: var(--radio-button-button-background-color);
	--radio-button-button-background-color-disabled: var(--flame-color-primary-lighter-grey);

	--radio-button-button-border-color: var(--input-border-color);
	--radio-button-button-border-color-active: var(--secondary-color);
	--radio-button-button-border-color-disabled: var(--flame-color-primary-light-grey);

	--radio-button-button-label-color: var(--input-label-color);
	--radio-button-button-label-color-active: var(--radio-button-button-border-color-active);
	--radio-button-button-label-color-disabled: var(--radio-button-button-border-color-disabled);

	// Radio-Button: Switch-Style
	--radio-button-switch-background-color: var(--flame-color-primary-white);
	--radio-button-switch-background-color-active: var(--secondary-color);
	--radio-button-switch-background-color-disabled: var(--flame-color-primary-light-sky);

	--radio-button-switch-border-color: var(--input-border-color);
	--radio-button-switch-border-color-active: var(--radio-button-switch-background-color-active);
	--radio-button-switch-border-color-disabled: var(--flame-color-primary-light-grey);

	--radio-button-switch-label-color: var(--input-label-color);
	--radio-button-switch-label-color-active: var(--flame-color-primary-white);
	--radio-button-switch-label-color-disabled: var(--radio-button-switch-border-color-disabled);

	// Radio-Box
	--radio-box-background-color: var(--flame-color-primary-white);
	--radio-box-border-width: 1px;
	--radio-box-border-width-active: 2px;
	--radio-box-border-color: var(--secondary-color);
	--radio-box-border-color-active: var(--radio-box-border-color);
	--radio-box-border-radius: var(--flame-shape-rounded-xs);

	--radio-box-check-size: 40px;
	--radio-box-check-size-small: 25px;
	--radio-box-check-border-width: 1px;
	--radio-box-check-border-width-active: 2px;
	--radio-box-check-border-color: var(--primary-color);
	--radio-box-check-background-color-active: var(--radio-box-check-border-color);
	--radio-box-check-color: var(--flame-color-primary-white);

	--radio-box-banner-background-color: var(--primary-color);
	--radio-box-banner-color: var(--flame-color-primary-white);

	--radio-box-icon-color: var(--primary-color);
}
