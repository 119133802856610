@import 'assets/styles/mixins/_breakpoints.scss';
@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';

.img {
	text-align: center;
	svg {
		width: 100%;
		max-width: 130px;
		height: auto;
		@include bp-min(sm) {
			max-width: 250px;
		}
	}
}

.amountBox {
	margin-top: 30px;
	padding: 20px 20px 20px 0;
	margin-bottom: 15px;
	border-top: 1px solid $primarySkyMedium;
	width: 100%;
}

.amount {

	font-weight: $font-weight-light;
	font-size: 40px;

	span {
		font-size: 24px;
	}

}
