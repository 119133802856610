@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.title {

	font-size: 26px;
	font-weight: $font-weight-light;
	color: $primaryBlack;
	line-height: $line-height-default;
	margin: 0;

	@include bp-min(md) {
		font-size: 32px;
		line-height: 1.25;
	}

	@include bp-min(lg) {
		font-size: 40px;
		line-height: 1.2;
	}

	&.subtitle {
		font-size: 18px;
		font-weight: $font-weight-bold;
		line-height: $line-height-default;
		margin: 0;
		color: $primaryGrey;

		@include bp-min(md) {
			font-size: 26px;
			line-height: 1.25;
		}

		@include bp-min(lg) {
			font-size: 26px;
			line-height: 1.2;
		}
	}

	&.marginBottom {
		margin-bottom: 20px;
	}

	&.isSmall {
		font-size: 24px;

		@include bp-min(md) {
			font-size: 28px;
		}

		@include bp-min(lg) {
			font-size: 28px;
		}

	}

}
