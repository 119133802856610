@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	cursor: pointer;
	padding-bottom: 30px;
	position: relative;
}

.label {

	position: relative;

	cursor: pointer;
	font-weight: var(--text-font-weight);
	line-height: var(--text-line-height);
	font-size: var(--checkbox-label-size-mobile);

	@include bp-min(sm) {
		font-size: var(--checkbox-label-size-tablet);
	}

	@include bp-min(lg) {
		font-size: var(--checkbox-label-size-desktop);
	}

	&,
	& > span {
		color: var(--checkbox-label-color);
	}

}

.pseudoCheckbox {

	flex-shrink: 0;
	position: relative;
	z-index: 2;
	margin-right: var(--checkbox-box-spacing);
	border-width: var(--checkbox-box-border-width);
	border-style: solid;
	border-color: var(--checkbox-box-border-color);
	border-radius: var(--checkbox-box-border-radius);
	background-color: var(--checkbox-box-background-color);

	width: var(--checkbox-box-size);
	height: var(--checkbox-box-size);

	& ~ .label {
		top: var(--checkbox-label-top);
	}

	& ~ .message {
		padding-left: calc(var(--checkbox-box-size) + var(--checkbox-box-spacing));
	}

}

.pseudoCheckboxCheck {

	width: 70%;
	height: 70%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	opacity: 0.00000001;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		fill: var(--checkbox-box-checkmark-color-checked);
	}

}

.checkbox {

	opacity: 0.0000001;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	&:checked {

		& ~ .pseudoCheckbox {

			border-color: var(--checkbox-box-border-color-checked);
			background-color: var(--checkbox-box-background-color-checked);
			border-width: var(--checkbox-box-border-width-checked);

			.pseudoCheckboxCheck {
				opacity: 1;
			}

		}

	}

	&:not([disabled]):focus,
	&:not([disabled]):active {

		& ~ .pseudoCheckbox {
			border-color: var(--checkbox-box-border-color-focus);
			background-color: var(--checkbox-box-background-color-focus);
			border-width: var(--checkbox-box-border-width-focus);
		}

		&:checked {

			& ~ .pseudoCheckbox {
				border-color: var(--checkbox-box-border-color-checked-focus);
				background-color: var(--checkbox-box-background-color-checked-focus);
			}

		}

	}

	&:disabled {

		& ~ .pseudoCheckbox {

			border-color: var(--checkbox-box-border-color-disabled);
			border-width: var(--checkbox-box-border-width-disabled);
			background-color: var(--checkbox-box-background-color-disabled);

			.pseudoCheckboxCheck {
				svg {
					fill: var(--checkbox-box-checkmark-color-disabled);
				}
			}

		}

	}

	&:not([disabled]).error {

		& ~ .pseudoCheckbox {
			border-color: var(--input-error-color);
		}

		& ~ .message {
			color: var(--input-error-message-color);
		}

	}

}

.message {
	bottom: 5px;
}
