body {
	background-color: $extraSkyLight;
	font-family: $font-family-santander;
	font-size: $font-size-default;
	line-height: $line-height-default;
}

.mt-15 {
	margin-top: 15px;
}
