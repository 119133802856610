@import 'assets/styles/mixins/_breakpoints.scss';

.mainContent {

	padding-top: 20px;
	padding-bottom: 70px;

	@include bp-min(sm) {
		padding-top: 45px;
		padding-bottom: 80px;
	}

}

.mainContentInner {
	position: relative;
}
