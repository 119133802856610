@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.steps {

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin-top: 24px;
	margin-bottom: 15px;

	@include bp-min(md) {
		flex-direction: row;
		margin-top: 40px;
		margin-bottom: 30px;
	}

}

.item {

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-grow: 1;

	margin-bottom: 8px;

	@include bp-min(md) {
		margin-bottom: 0;
	}

}

.icon {

	flex-shrink: 0;
	width: 48px;
	height: 48px;
	background-color: $primarySky;
	border-radius: 50%;

	position: relative;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

}

.title {

	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $primaryBlack;
	font-weight: 400;
	font-size: 16px;
	padding-left: 20px;
	line-height: 1;

}

.arrow {

	width: 48px;
	height: 48px;
	margin-left: 15px;
	margin-right: 15px;
	position: relative;

	svg {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	display: none;

	@include bp-min(md) {
		display: block;
	}

}
