@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

$footerHeight: 50px;

.footer {

	width: 100vw;
	height: $footerHeight;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: $primarySky;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;

}

.footerInner {

	width: 100%;

	@include bp-max(xs) {
		font-size: 12px;
	}

}

.footerTextSpacer {

	color: $linkColor;

	&:before {
		content: ' '
	}

	&:after {
		content: ' '
	}

}
