@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.wrapper {

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 5px 0;

	@include bp-min(sm) {
		flex-wrap: nowrap;
		padding: 0;
	}

}

.item {

	width: 33.333333%;
	font-size: 10px;
	line-height: 1.2;
	color: $primaryGrey;
	font-weight: 400;
	padding: 5px 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@include bp-min(sm) {
		width: 16.666666%;
		font-size: 10px;
		padding: 10px 15px;
	}

	@include bp-min(lg) {
		font-size: 11px;
		padding: 15px 20px;
	}

}

.itemBorderRight {

	position: relative;

	@include bp-min(sm) {

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 1px;
			height: 100%;
			background-color: $primarySky;
		}

	}

}

.itemBold {
	font-weight: 700;
}

.itemHint {

	display: block;
	font-size: 10px;
	line-height: 1.2;
	color: $primaryGrey;
	font-weight: 700;

	@include bp-min(sm) {
		display: none;
	}

}
