@import 'assets/styles/mixins/_breakpoints.scss';
@import 'assets/styles/variables/_colors.scss';

.wrapper {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
	border-radius: 6px;
	overflow: hidden;
}

.header {

	width: 100%;
	height: 90px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../../../assets/img/thank_you_header.png);

	@include bp-max(xs) {
		background-position: -80px center;
	}

}

.content {

	padding: 25px;
	background-color: $primaryWhite;

	@include bp-max(md) {
		padding: 25px 30px;
	}

	h1 {

		font-size: 26px;

		@include bp-max(md) {
			font-size: 32px;
		}

	}

	p:last-child {
		margin-bottom: 0;
	}

}
