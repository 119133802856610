@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';
@import 'assets/styles/mixins/_btn.scss';

$transitionTime: 0.2s;

.wrapper {

	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
	border-radius: 6px;
	overflow: hidden;
	margin-bottom: 15px;

	@include bp-min(md) {
		margin-bottom: 40px;
	}

}

.header {

	width: 100%;
	height: 90px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../../../assets/img/bco_response_green_credit_header.png);

	@include bp-max(xs) {
		display: none;
	}

}

.content {

	padding: 25px;
	background-color: $primaryWhite;

	@include bp-min(md) {
		padding: 25px 30px;
	}

	p:last-child {
		margin-bottom: 0;
	}

	p {
		font-size: 14px;

		@include bp-min(md) {
			font-size: 16px;
		}
	}

}

.applicationId {
	color: $primaryGrey;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 20px;
	position: relative;
	display: inline-block;

	* {
		color: $primaryGrey;
	}

	&:after {
		content: '';
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: -5px;
		background-color: $primaryGrey;
	}
}

.box {
	padding: 10px 12px;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
	background-color: $primaryWhite;
}

.amount {

	color: $secondaryTurquoise;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.14;

	@include bp-min(sm) {
		font-size: 20px;
	}

	strong {

		color: $secondaryTurquoise;
		font-size: 26px;
		font-weight: 400;

		@include bp-min(sm) {
			font-size: 42px;
		}

	}

}

.moreDetailsLink {

	width: 100%;
	display: flex;
	align-items: flex-end;
	margin: 0;
	margin-top: 10px;
	margin-bottom: 10px;
	text-decoration: underline !important;
	font-size: 14px;
	cursor: pointer;

	@include bp-min(md) {
		position: relative;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	svg {
		position: relative;
		top: -6px;
		margin-left: 7px;
		transition: all $transitionTime ease-in-out;
	}

}

.moreDetailsLinkActive {
	svg {
		transform: rotate(-180deg);
	}
}

.details {

	max-height: 0;
	overflow: hidden;
	transition: max-height $transitionTime ease-out;

	@include bp-min(md) {
		padding-top: 0;
		margin-top: -10px;
	}

}

.detailsActive {
	max-height: 1000px;
	transition: max-height $transitionTime ease-in;
}

.table {
	display: table;
	width: 100%;
}

.tableRow {
	display: table-row;
}

.tableCell {
	display: table-cell;
	padding-top: 15px;
	color: $primaryGrey;

	font-size: 12px;

	@include bp-min(md) {
		font-size: 14px;
	}
}

.tableCellLabel {
	font-weight: 400;
}

.tableCellValue {
	font-weight: 700;
	padding-left: 10px;

	width: 110px;

	@include bp-min(md) {
		width: auto;
	}
}
