@import 'assets/styles/variables/_colors.scss';

.wrapper {

	border-bottom: 2px solid $primarySky;

	&:first-child {
		border-top: 2px solid $primarySky;
	}

	&:nth-child(odd) {
		background-color: $primarySkyLight;
	}

	&:nth-child(even) {
		background-color: $primaryWhite;
	}

	&.isOpen {

		.body {
			display: block;
		}

		.headerArrow {
			svg {
				transform: rotate(180deg);
			}
		}

	}

}

.header {
	cursor: pointer;
	display: flex;
	flex-direction: row;
}

.headerContent {
	flex-grow: 1;
	align-self: stretch;
}

.headerArrow {

	align-self: stretch;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 15px;
	padding-left: 15px;

	svg {
		width: 15px;
		height: auto;
	}

}

.body {
	display: none;
}
