@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.box {
	background-color: $primaryWhite;
	border-radius: 5px;
	box-shadow: 0 12px 14px -13px rgb(0 0 0 / 11%);
	padding: 15px;
	margin-bottom: 15px;
}

.header {

	display: flex;
	flex-direction: row;
	padding-bottom: 15px;

	@include bp-min(sm) {
		padding-bottom: 30px;
	}

}

.headerIcon {

	flex-grow: 0;

	svg {

		width: 20px;
		height: auto;
		margin-right: 10px;

		@include bp-min(sm) {
			margin-right: 20px;
		}

	}

}

.headerContent {
	flex-grow: 1;
}

.title {

	margin-top: 0;
	margin-bottom: 5px;
	font-size: 16px;

	@include bp-min(sm) {
		font-size: 18px;
	}

}

.subtitle {
	margin-top: 0;
	margin-bottom: 0;
	line-height: 1.2;
	color: $primaryGrey;

	& > * {
		color: $primaryGrey;
	}

}

.body {
	overflow: hidden;
	max-height: 0;
	transition: 0.5s;
}

.divider {
	width: 0;
	height: 1px;
	background-color: $primaryGrey50;
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
	transition: 0.5s;
}


.toggle {

	font-size: 12px;
	text-align: right;
	padding: 10px 0;
	padding-top: 15px;

	@include bp-min(sm) {
		padding-top: 15px;
	}

	svg {
		transform: translate(0, -1px);
		transition: 0.2s;
	}

}

.active {

	.toggle {

		svg {
			transform: rotate(-180deg);
		}

	}

	.body {
		max-height: 1000px;

		@include bp-min(sm) {
			max-height: 1000px;
		}

	}

	.divider {
		width: 100%;
	}

}
