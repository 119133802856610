@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	display: block;
	position: relative;
	line-height: 1.0;
	border-radius: 50%;
	cursor: pointer;
	border-style: solid;
	overflow: hidden;
	border-width: var(--radio-button-border-width);
	border-color: var(--radio-button-color);
	transition: background-color 0.15s ease;

	width: var(--radio-button-size-mobile);
	height: var(--radio-button-size-mobile);

	@include bp-min(sm) {
		width: var(--radio-button-size-tablet);
		height: var(--radio-button-size-tablet);
	}

	@include bp-min(lg) {
		width: var(--radio-button-size-desktop);
		height: var(--radio-button-size-desktop);
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		height: 80%;
		transition: opacity 0.15s ease;
		opacity: 0.000000001;
		fill: #ffffff;
	}

	&.active {

		background-color: var(--radio-button-color);

		svg {
			opacity: 1;
		}

	}

}
