@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.title {

	font-weight: $font-weight-light;
	font-size: 14px;
	line-height: 1.2;
	padding-left: 15px;

	@include bp-min(sm) {

		position: relative;
		font-size: 24px;
		line-height: 1.5;
		padding-left: 30px;

		br {
			display: none;
		}

	}

	strong {
		font-weight: $font-weight-bold;
		display: inline-block;
		margin-right: 10px;
	}

}

.hasLine {

	@include bp-min(sm) {

		&:before {
			content: '';
			height: 30px;
			width: 1px;
			background-color: rgba(0, 0, 0, 0.5);
			position: absolute;
			top: calc(50% + 2px);
			left: 15px;
			transform: translate(0, -50%);
		}

	}

}
