@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	&,
	* {
		box-sizing: border-box;
	}

	position: relative;
	padding-bottom: 10px;

	&.hasMessage {
		padding-bottom: 30px;
	}

}

.label {

	position: absolute;
	color: var(--input-label-color);
	transition: var(--transition-default);
	cursor: text;
	pointer-events: none;

	font-weight: var(--input-label-font-weight);

	font-size: var(--input-label-font-size-mobile);
	top: var(--input-label-position-top-mobile);
	left: var(--input-label-position-left-mobile);

	@include bp-min(sm) {
		font-size: var(--input-label-font-size-tablet);
		top: var(--input-label-position-top-tablet);
		left: var(--input-label-position-left-tablet);
	}

	@include bp-min(lg) {
		font-size: var(--input-label-font-size-desktop);
		top: var(--input-label-position-top-desktop);
		left: var(--input-label-position-left-desktop);
	}

}

.message {

	position: absolute;
	left: 0;
	bottom: 13px;
	color: var(--input-message-color);
	font: var(--input-message-font-mobile);
	transition: var(--transition-default);
	opacity: 0.00000001;
	pointer-events: none;

	@include bp-min(sm) {
		font: var(--input-message-font);
	}

	&.show {
		opacity: 1;
		pointer-events: all;
	}

}

.input {

	width: 100%;
	display: block;

	height: var(--input-height-mobile);
	padding: var(--input-padding-mobile);
	font-size: var(--input-font-size-mobile);

	@include bp-min(sm) {
		height: var(--input-height-tablet);
		padding: var(--input-padding-tablet);
		font-size: var(--input-font-size-tablet);
	}

	@include bp-min(lg) {
		height: var(--input-height-desktop);
		padding: var(--input-padding-desktop);
		font-size: var(--input-font-size-desktop);
	}

	font-weight: var(--input-font-weight);

	border-style: var(--input-border-style);

	border-top-color: var(--input-border-top-color);
	border-bottom-color: var(--input-border-bottom-color);
	border-left-color: var(--input-border-left-color);
	border-right-color: var(--input-border-right-color);

	border-top-width: var(--input-border-top-width);
	border-bottom-width: var(--input-border-bottom-width);
	border-left-width: var(--input-border-left-width);
	border-right-width: var(--input-border-right-width);

	border-radius: var(--input-border-radius);

	background-color: var(--input-background-color);
	color: var(--input-color);
	box-shadow: var(--input-box-shadow);
	outline: none;
	transition: var(--transition-default);

	&.hasTooltip {
		padding-right: 50px;
	}

	&.hasSuffix {
		padding-right: 40px;
	}

	&.hasSuffix.hasTooltip {
		padding-right: 65px;
	}

	&.active,
	&:not([disabled]:not(.interactionDisabled)):focus,
	&:not([disabled]:not(.interactionDisabled)):active {

		& ~ .label {

			font-size: var(--input-label-font-size-mobile-active);
			top: var(--input-label-position-top-mobile-active);

			@include bp-min(sm) {
				font-size: var(--input-label-font-size-tablet-active);
				top: var(--input-label-position-top-tablet-active);
			}

			@include bp-min(lg) {
				font-size: var(--input-label-font-size-desktop-active);
				top: var(--input-label-position-top-desktop-active);
			}

		}

	}

	&:not([disabled]:not(.interactionDisabled)):focus {

		border-top-color: var(--input-border-top-color-active);
		border-bottom-color: var(--input-border-bottom-color-active);
		border-left-color: var(--input-border-left-color-active);
		border-right-color: var(--input-border-right-color-active);

		border-top-width: var(--input-border-top-width-active);
		border-bottom-width: var(--input-border-bottom-width-active);
		border-left-width: var(--input-border-left-width-active);
		border-right-width: var(--input-border-right-width-active);

		& ~ .label {
			color: var(--input-label-color-active);
		}

	}

	&:disabled {
		cursor: not-allowed;
	}

	&:disabled:not(.interactionDisabled) {

		color: var(--input-disabled-color);
		border-color: var(--input-disabled-border-color);
		background-color: var(--input-disabled-background-color);

		& ~ .label {
			color: var(--input-label-color-disabled);
		}

		& ~ .suffix {
			color: var(--input-label-color-disabled);
		}

		&:not(.error) {
			& ~ .message {
				color: var(--input-label-color-disabled);
			}
		}

	}

	&:not([disabled]:not(.interactionDisabled)).error {

		border-top-color: var(--input-border-top-color-error);
		border-bottom-color: var(--input-border-bottom-color-error);
		border-left-color: var(--input-border-left-color-error);
		border-right-color: var(--input-border-right-color-error);

		& ~ .label {
			color: var(--input-label-color-error);
		}

		& ~ .message {
			color: var(--input-error-message-color);
		}

	}

	&.s {

		height: var(--input-height-s-mobile);
		padding: var(--input-padding-s-mobile);
		font-size: var(--input-font-size-s-mobile);

		@include bp-min(sm) {
			height: var(--input-height-s-tablet);
			padding: var(--input-padding-s-tablet);
			font-size: var(--input-font-size-s-tablet);
		}

		@include bp-min(lg) {
			height: var(--input-height-s-desktop);
			padding: var(--input-padding-s-desktop);
			font-size: var(--input-font-size-s-desktop);
		}

		& ~ .label {

			font-size: var(--input-label-font-size-s-mobile);
			top: var(--input-label-position-top-s-mobile);
			left: var(--input-label-position-left-s-mobile);

			@include bp-min(sm) {
				font-size: var(--input-label-font-size-s-tablet);
				top: var(--input-label-position-top-s-tablet);
				left: var(--input-label-position-left-s-tablet);
			}

			@include bp-min(lg) {
				font-size: var(--input-label-font-size-s-desktop);
				top: var(--input-label-position-top-s-desktop);
				left: var(--input-label-position-left-s-desktop);
			}

		}

		&.active,
		&:not([disabled]:not(.interactionDisabled)):focus,
		&:not([disabled]:not(.interactionDisabled)):active {

			& ~ .label {

				font-size: var(--input-label-font-size-s-mobile-active);
				top: var(--input-label-position-top-s-mobile-active);

				@include bp-min(sm) {
					font-size: var(--input-label-font-size-s-tablet-active);
					top: var(--input-label-position-top-s-tablet-active);
				}

				@include bp-min(lg) {
					font-size: var(--input-label-font-size-s-desktop-active);
					top: var(--input-label-position-top-s-desktop-active);
				}

			}

		}

	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

}

.suffix {

	position: absolute;
	right: var(--input-suffix-right);
	color: var(--input-label-color);
	font-size: var(--input-suffix-font-size);
	font-weight: var(--input-suffix-font-weight);

	top: var(--input-suffix-top-mobile);

	@include bp-min(sm) {
		top: var(--input-suffix-top-tablet);
	}

	@include bp-min(lg) {
		top: var(--input-suffix-top-desktop);
	}


	svg {
		fill: var(--primary-color);
	}

}

.tooltip {

	position: absolute;
	top: var(--input-tooltip-top);
	right: var(--input-tooltip-right);

	& ~ .suffix {
		right: var(--input-suffix-right-tooltip);
	}

}


