@import 'src/assets/styles/mixins/_breakpoints.scss';

.wrapper {

	text-align: center;

	@include bp-min(sm) {
		text-align: left;
	}

	button {

		height: auto;

		&:not(:last-child) {
			margin-bottom: 5px;
		}

	}

}
