.__react_component_tooltip {

	border-radius: 2px !important;
	padding: 12px 15px !important;
	max-width: 300px !important;

	&.show {
		opacity: 1 !important;
	}

}

.type-dark {

}

.type-light {

	background-color: $secondaryTurquoiseExtraLight !important;
	color: $primaryGrey !important;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3) !important;

	&.place-top {
		&:after {
			border-top-color: $secondaryTurquoiseExtraLight !important;
		}
	}

	&.place-bottom {
		&:after {
			border-bottom-color: $secondaryTurquoiseExtraLight !important;
		}
	}

	&.place-left {
		&:after {
			border-left-color: $secondaryTurquoiseExtraLight !important;
		}
	}

	&.place-right {
		&:after {
			border-right-color: $secondaryTurquoiseExtraLight !important;
		}
	}

}
