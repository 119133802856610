@import 'assets/styles/mixins/_breakpoints.scss';

.logo {

}

.isDesktop {
	display: none;
	@include bp-min(sm) {
		display: block;
	}
}

.isMobile {
	display: block;
	@include bp-min(sm) {
		display: none;
	}
}
