$font-family-santander: 'SantanderText', sans-serif;

$font-size-default: 16px;
$font-size-default-mobile: 12px;
$font-size-small: 14px;
$font-size-small-mobile: 10px;
$line-height-default: 1.3333333;

$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 300;
