@import 'assets/styles/mixins/_breakpoints.scss';
@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';
@import 'assets/styles/mixins/_btn.scss';

.wrapper {
	padding: 5px 20px 20px;
	background-color: $primaryWhite;
	border-radius: 5px;
	box-shadow: 0 12px 14px -13px $primaryGreyLight;
	border: solid 1px $primarySky;
	width: 100%;
	margin-top: 10px;
}

.row {
	display: flex;
	flex-direction: row;
}

.label,
.value {
	color: $primaryBlack;
}

.label {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	font-size: 14px;
	font-weight: $font-weight-light;
}

.labelTitle {
	display: inline-block;
	margin-right: 10px;
}

.value {
	flex-grow: 0;
	font-size: 18px;
	font-weight: $font-weight-bold;
}

.addExternalCreditButton {

	width: 100%;
	height: 40px;
	border-radius: 5px;
	background-color: $primarySky;
	position: relative;
	cursor: pointer;
	font-size: 12px;
	line-height: 40px;
	color: #6f7779;
	padding: 0 15px;
	margin-bottom: 30px;
	margin-top: 15px;

	@include bp-min(sm) {
		max-width: 50%;
		font-size: 14px;
		padding: 0 30px;
	}

	svg {
		position: absolute;
		top: 8px;
		right: 15px;
	}

}
