@import 'assets/styles/mixins/_breakpoints.scss';
@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_btn.scss';

.wrapper {

	margin-bottom: 30px;
	position: relative;

	@include bp-min(sm) {
		&:not(:last-child) {
			margin-bottom: 0;
		}
	}

}

.iban {

}

.remainingBalance {

	padding-right: 20px;

	@include bp-max(xs) {
		padding-right: 0;
		padding-bottom: 20px;
	}

}

.removeButton {

	background-color: $primarySky;
	width: 20px;
	height: 20px;
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
	position: absolute;
	top: 5px;
	right: 0;
	cursor: pointer;

	@include bp-max(xs) {
		border-top-right-radius: 0;
		border-bottom-left-radius: 50%;
		top: auto;
		bottom: 0;
		right: 5px;
		width: 30px;
		height: 30px;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

}
