@import 'assets/styles/mixins/_breakpoints.scss';

.editButtonWrapper {

	display: flex;
	flex-direction: row;
	justify-content: right;
	margin-top: -10px;
	margin-bottom: 15px;

	@include bp-min(sm) {
		margin-top: -20px;
	}

	@include bp-min(md) {
		margin-top: -30px;
		margin-bottom: 15px;
	}

}
