.logo {

	display: inline-block;
	margin-right: 5px;

	svg {
		transform: translate(0, 1px);
		height: 18px;
		width: auto;
	}

}
