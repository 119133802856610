@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.wrapper {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	background-color: $secondaryPurpleLighter;
	margin-bottom: 10px;
	padding: 5px 10px;
	border-radius: 6px;
	transition: 0.2s;

	&:hover {
		background-color: #f6ebf0;
	}
}

.col {
	&:first-child {
		flex-grow: 1;
	}
}

.name {
	font-size: $font-size-default-mobile;
	font-weight: $font-weight-regular;
	color: $linkColor;
	line-height: $line-height-default;

	@include bp-min(sm) {
		font-size: $font-size-default;
	}
}

.filename {
	font-size: 10px;
	font-weight: $font-weight-regular;
	color: $primaryGrey;
	line-height: $line-height-default;

	@include bp-min(sm) {
		font-size: 12px;
	}
}
