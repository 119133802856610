@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/variables/_fonts.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

$iconSize: 30px;
$iconSizeSmall: 20px;

.text {

	position: relative;
	font-size: $font-size-default-mobile;
	font-weight: $font-weight-regular;
	color: $primaryGrey;
	line-height: $line-height-default;
	margin: 0;

	* {
		color: $primaryGrey;
	}

	@include bp-min(sm) {
		font-size: $font-size-default;
	}

}

.black {
	color: $primaryBlack;
}

.textPrependIcon {
	padding-left: $iconSize + 15px;
	&.smallIcon {
		padding-left: $iconSizeSmall + 8px;

		.icon {
			svg {

				width: $iconSizeSmall - 2px;
				transform: translate(0, 1px);

				@include bp-min(sm) {
					width: $iconSizeSmall;
					transform: translate(0, 3px);
				}

			}
		}

	}
}

.textAppendIcon {
	padding-right: $iconSize + 15px;
	&.smallIcon {
		padding-left: $iconSizeSmall + 8px;

		.icon {
			svg {

				width: $iconSizeSmall - 2px;
				transform: translate(0, 1px);

				@include bp-min(sm) {
					width: $iconSizeSmall;
					transform: translate(0, 3px);
				}

			}
		}

	}
}

.title {
	font-size: 18px;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;
	margin: 0;

	@include bp-min(md) {
		font-size: 26px;
		line-height: 1.25;
	}

	@include bp-min(lg) {
		font-size: 26px;
		line-height: 1.2;
	}
}

.subtitle {
	margin-top: 12px;
	margin-bottom: 20px;
}

.icon {

	position: absolute;
	top: 50%;
	transform: translate(0, -50%);

	svg {
		width: $iconSize;
		height: auto;
	}

}

.prependIcon {
	left: 0;
}

.appendIcon {
	right: 0;
}

.small {

	font-size: $font-size-small-mobile;

	@include bp-min(sm) {
		font-size: $font-size-small;
	}

}

.extraSmall {

	font-size: 8px;

	@include bp-min(sm) {
		font-size: 10px;
	}

}

.marginTop {
	margin-top: 15px;
}

.marginBottom {
	margin-bottom: 15px;
}
