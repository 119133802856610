@import 'src/assets/styles/mixins/_breakpoints.scss';

.button {

	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: 1px solid;
	border-radius: var(--button-border-radius);
	box-sizing: border-box;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	box-shadow: var(--button-box-shadow);
	outline: none;
	min-width: 135px;

	height: var(--button-height-mobile);

	@include bp-min(sm) {
		height: var(--button-height-tablet);
	}

	@include bp-min(lg) {
		height: var(--button-height-desktop);
	}

	&.fullWidthMobile {
		@include bp-max(xs) {
			width: 100%;
		}
	}

	&.fullWidthTablet {
		@media (min-width: #{$sm-min}) and  (max-width: #{$md-max}) {
			width: 100%;
		}
	}

	&.fullWidthDesktop {
		@include bp-min(lg) {
			width: 100%;
		}
	}

	.text {

		display: inline-block;
		font-weight: var(--button-font-weight);
		text-transform: var(--button-text-transform);
		letter-spacing: var(--button-letter-spacing);
		white-space: nowrap;

		font-size: var(--button-fonts-size-mobile);

		@include bp-min(sm) {
			font-size: var(--button-fonts-size-tablet);
		}

		@include bp-min(lg) {
			font-size: var(--button-fonts-size-desktop);
		}

		svg {
			height: 12px;
			width: auto;
			&:first-child {
				margin-right: 10px;
			}
			&:last-child {
				margin-left: 10px;
			}
		}

	}

	.icon {

		display: inline-block;

		&:first-child {
			margin-right: 10px;
		}
		&:last-child {
			margin-left: 10px;
		}

		svg {
			height: 12px;
			width: auto;
		}

	}

	&.s {

		height: 30px;
		padding: var(--button-padding-s-mobile);

		@include bp-min(sm) {
			padding: var(--button-padding-s-tablet);
		}

		@include bp-min(lg) {
			padding: var(--button-padding-s-desktop);
		}

		.text {
			font-size: 10px;
			letter-spacing: 1px;
		}

		.icon {


			&:first-child {
				margin-right: 5px;
			}

			&:last-child {
				margin-left: 5px;
			}

			svg {
				height: 12px;
				transform: translate(0, 1px);
			}

		}

	}

	&.m {

		padding: var(--button-padding-m-mobile);

		@include bp-min(sm) {
			padding: var(--button-padding-m-tablet);
		}

		@include bp-min(lg) {
			padding: var(--button-padding-m-desktop);
		}

	}

	&.l {

		padding: var(--button-padding-l-mobile);

		@include bp-min(sm) {
			padding: var(--button-padding-l-tablet);
		}

		@include bp-min(lg) {
			padding: var(--button-padding-l-desktop);
		}

	}

	&.primary:not(:disabled) {

		background: var(--button-primary-background-color);
		border-color: var(--button-primary-border-color);

		.text {
			color: var(--button-primary-text-color);
			svg {
				fill: var(--button-primary-text-color);
			}
		}

		&:hover,
		&:focus-visible {
			background: var(--button-primary-background-color-hover);
			border-color: var(--button-primary-border-color-hover);
		}

		&:active {
			background: var(--button-primary-background-color-active);
			border-color: var(--button-primary-border-color-active);
		}

	}

	&.secondary:not(:disabled) {

		background: var(--button-secondary-background-color);
		border-color: var(--button-secondary-border-color);

		.text {
			color: var(--button-secondary-text-color);
			svg {
				fill: var(--button-secondary-text-color);
			}
		}

		&:hover,
		&:focus-visible {
			background: var(--button-secondary-background-color-hover);
			border-color: var(--button-secondary-border-color-hover);
		}

		&:active {
			background: var(--button-secondary-background-color-active);
			border-color: var(--button-secondary-border-color-active);
		}

	}

	&.link {

		box-shadow: none;
		background: transparent;
		border: none;
		border-radius: 0;
		padding-left: 0;
		padding-right: 0;
		min-width: auto;

		.text {

			text-transform: none;
			letter-spacing: normal;

			font-size: var(--text-font-size-m-mobile);

			@include bp-min(sm) {
				font-size: var(--text-font-size-m-tablet);
			}

			@include bp-min(lg) {
				font-size: var(--text-font-size-m-desktop);
			}

		}

		&:not(:disabled) {

			.text {
				color: var(--button-link-text-color);
				svg {
					fill: var(--button-link-text-color);
				}
			}

			&:hover,
			&:focus-visible {
				.text {
					color: var(--button-link-text-color-hover);
					svg {
						fill: var(--button-link-text-color-hover);
					}
				}
			}

			&:active {
				.text {
					color: var(--button-link-text-color-active);
					svg {
						fill: var(--button-link-text-color-active);
					}
				}
			}

		}

	}

	&:not(.link).disabled {
		background: var(--button-disabled-background-color);
		border: 1px solid var(--button-disabled-border-color);

		.text {
			color: var(--button-disabled-text-color);
		}
	}

}

.loadingAnimation {

	display: flex;
	flex-direction: row;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	& > span {
		display: inline-block;
		width: 10px;
		height: 10px;
		background-color: rgba(255, 255, 255, 0.85);
		border-radius: 50%;
		margin-left: 2px;
		margin-right: 2px;
		animation: buttonLoadingAnimation 1.4s infinite ease-in-out both;
	}

	& > .loadingAnimationBounce1 {
		animation-delay: -0.32s;
	}

	& > .loadingAnimationBounce2 {
		animation-delay: -0.16s;
	}

}

@keyframes buttonLoadingAnimation {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}
