@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

$navbarHeight: 100px;
$navbarHeightMobile: 80px;
$navbarBorderTopSize: 5px;
$navbarBorderBottomSize: 1px;

.navbar {

	background-color: $primaryWhite;
	border-top: $navbarBorderTopSize solid $secondaryPurpleDark;
	border-bottom: $navbarBorderBottomSize solid $extraSky;

	width: 100vw;
	height: $navbarHeightMobile;

	@include bp-min(sm) {
		height: $navbarHeight;
	}

}

.navbarInner {

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	width: 100%;
	height: $navbarHeightMobile - $navbarBorderTopSize - $navbarBorderBottomSize;

	@include bp-min(sm) {
		height: $navbarHeight - $navbarBorderTopSize - $navbarBorderBottomSize;
	}

}

.item {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.itemGrow {
	flex-grow: 1;
}

.itemNoShrink {
	flex-shrink: 0;
}
