.iconWrapper {
	margin-bottom: 30px;
	svg {
		width: 100px;
		height: auto;
	}
}

.buttonWrapper {
	justify-content: center;
	display: flex;
	flex-direction: row;
	margin-top: 30px;
}
