@import 'assets/styles/mixins/_breakpoints.scss';

.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.justify-content-start {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.justify-content-end {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.justify-content-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.justify-content-between {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.justify-content-around {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.noWrap {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.flexColumnMobile {
	@include bp-max(xs) {

		flex-direction: column;

		> div {
			flex-basis: auto;
			margin-bottom: 10px;
		}

	}
}
