@import 'assets/styles/mixins/_breakpoints.scss';

.submitFooter {
	margin-top: 20px;
}

.secureConnection {
	width: 100%;
	text-align: right;
}

.alerts {
	@include bp-min(sm) {
		position: fixed;
		bottom: 35px;
		left: 0;
		width: 100%;
		z-index: 99999999;
		pointer-events: none;
	}
}
