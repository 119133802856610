@import 'assets/styles/variables/_colors.scss';
@import 'assets/styles/mixins/_breakpoints.scss';

.buttonWrapper {
	padding-top: 10px;
}

.header {
	display: none;
	flex-direction: row;

	@include bp-min(sm) {
		display: flex;
	}
}

.headerItem {

	width: calc((100% - 45px) / 6);
	font-size: 12px;
	line-height: 1.2;
	color: $primaryGrey;
	font-weight: 700;
	padding: 5px 10px;
	white-space: nowrap;

	@include bp-min(sm) {
		padding: 10px 15px;
	}

	@include bp-min(lg) {
		font-size: 14px;
		padding: 15px 20px;
	}

}
