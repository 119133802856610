.spinner {

	margin: 30px;

	svg {
		animation-name: spin;
		animation-duration: 2000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

}

.noMargin {
	margin: 0
}

@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}
