@import 'assets/styles/mixins/_btn.scss';

$transitionTime: 0.2s;

@each $breakpoint in $breakpoints {

	@include bp-min($breakpoint) {

		$bp: '';
		@if $breakpoint {
			$bp: '-' + $breakpoint;
		}

		.text-align#{$bp} {
			&-left {
				text-align: left;
			}
			&-right {
				text-align: right;
			}
			&-center {
				text-align: center;
			}
		}

	}

}

.link {

	color: $linkColor !important;
	text-decoration: none;
	cursor: pointer;
	font-weight: $font-weight-bold;
	transition: all $transitionTime ease-in-out;

	* {
		color: $linkColor !important;
	}

	&.linkExternalIcon {
		&:after {
			content: '';
			display: inline-block;
			width: 8px;
			height: 8px;
			margin-left: 3px;
			margin-right: 1px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: left bottom;
			background-image: url(../img/svg/svg_external_link.svg);

			@include bp-min(sm) {
				width: 10px;
				height: 10px;
				margin-left: 5px;
			}

		}
	}

	svg {

		fill: $secondaryPurpleDark;
		* {
			fill: $secondaryPurpleDark;
		}

		&:first-child {
			margin-right: 10px;
		}

		&:last-child {
			margin-left: 10px;
		}
	}

	&:hover {
		color: $linkColorActive !important;
		* {
			color: $linkColorActive !important;
		}
	}

}

.underlined {
	text-decoration: underline;
}

.span {
	&--margin {
		display: inline-block;
		margin-top: 15px;
		margin-bottom: 15px;
	}
}
